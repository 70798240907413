import Vue from 'vue'

export const store = Vue.observable({
    user: { // 用户信息
        id: '',
        tel: '',
        wxopenid: '',
        name: ''
    },
    patientId: "",
    phoneNumber: "",
    userId: "",
    hospitals: [],
    hospital: {
        id: '',
        shortName: '',
        name: '',
        image3dServiceUrl: '',
        enableFlag: true,
        identityCheckMode:'idCard',       //name:校验姓名; idCard:校验身份证号后四位
    },
    isAuthenticated: false,
    isProfessional: false,

    url: { // 有关url
        originalUrl: "",
        actualUrl: "",
        hostUrl: "",
        baseUrl: "",
        imageUrl: "",
    },
    originalUrl: "",
    actualUrl: "",
    hostUrl: "",
    baseUrl: "",
    imageUrl: "",
    showHome: false,
    
    JSAPIPay: false,  // JSAPIPay JSAPI支付方式 微信或者有可能支付宝
    payWay: ['微信'], // 目前只有微信， 以后可能会有支付宝。。
    wx: 
    // { // 移动产品公众号 --本司公众号
    //     appid: 'wx9cbc9154ab93c1ee',// 微信公众号APPID
    //     secret: 'a41393f6c19de561cef2cb6879f5594c', // 公众号密码
    //     ORDER_APP_ID: "23659222", // 商户号
    //     ORDER_APP_KEY: "493d57d28b1d4364", // 自己定义
    //     ORDER_APP_SECRET: "b50bda0dc76c3adc2240c4b2de92bb9c21c846ed6113e5ef812e9ca47fc49424001cfbe882e7b3d209f5437afa6b8201b68bd2a40a2ffea45400c96e214f3e12", // 自己定义
    //     access_token: "",
    //     expires_in: '',
    //     openid: "",
    //     refresh_token: "",
    //     scope: "",
    //     orderapi: "https://testdlydwx.cinyi.com/payWeixin/01/api/jsapi/pay", // 生成订单api
    // }
   //  { // 医信云科公众号 --天津南开医院公众号
   //      appid: 'wx25d6b42c41fdadac',// 微信公众号APPID
   //      secret: 'aedd05988beabc6ba17b636c3ae027ae', // 公众号密码
   //      ORDER_APP_ID: "1602256243",
   //      ORDER_APP_KEY: "YxYk1s2e3n4y5i6n7tTjNanKai891011",
   //      ORDER_APP_SECRET: "",
   //      access_token: "",
   //      expires_in: '',
   //      openid: "",
   //      refresh_token: "",
   //      scope: "",
   //      orderapi: "http://127.0.0.1:8080/pay/getpayjson.do", // 生成订单api
   // }
        { // 英迪尔公众号
            appid: 'wx26530ebc241a462d',// 微信公众号APPID
            secret: '', // 公众号密码
            ORDER_APP_ID: "",
            ORDER_APP_KEY: "",
            ORDER_APP_SECRET: "",
            access_token: "",
            expires_in: '',
            openid: "",
            refresh_token: "",
            scope: "",
            orderapi: "", // 生成订单api
        }
})

